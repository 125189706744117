import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Organization } from '@prlw/core/profile/organization.entity';

@Component({
  selector: 'prlw-profile-organization-info-ui',
  templateUrl: './profile-organization-info.component.html',
  styleUrls: ['./profile-organization-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProfileOrganizationInfoComponent {
  @Input() public organization!: Organization;
  @Input() public areRegulationsSigned!: boolean;

  public regulationsHintVisible = false;
}
