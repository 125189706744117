import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'prlw-license-cards',
  templateUrl: './license-cards.component.html',
  styleUrls: ['./license-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LicenseCardsComponent {
  @Input() public isLight: boolean = false;
  public form: UntypedFormGroup;

  constructor(private readonly fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      price: '100',
    });
  }

  public onBuyLicense(): void {
    const value = this.form.get('price')?.value;
    if (value) {
      console.log(value);
    }
  }
}
