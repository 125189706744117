import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Organization } from '@prlw/core/profile/organization.entity';

@Component({
  selector: 'prlw-profile-add-organization-ui',
  templateUrl: './profile-add-organization.component.html',
  styleUrls: ['./profile-add-organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProfileAddOrganizationComponent {
  @Input() public foundOrganization: Organization | null | undefined;
  @Output()
  public readonly searchOrganizationByInn = new EventEmitter<string>();
  @Output() public readonly confirmOrganization = new EventEmitter<void>();

  public step = 1;
  public inn = '';

  // Specially for Firefox
  public filterNumbers(e: KeyboardEvent): void {
    if (!/\d/.test(e.key)) {
      e.preventDefault();
    }
  }

  public advanceToStep2(): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.step = 2;
    this.searchOrganizationByInn.emit(this.inn.toString());
  }
}
