import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

@Component({
  selector: 'prlw-file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.scss'],
  imports: [ResponsiveDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadProgressComponent {
  @Input() public progress!: number;

  public get calculateProgressPercent(): number {
    return this.progress;
  }
}
