import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { IPage } from '@prlw/common/page/page.interface';

@Directive({
  selector: '[prlwRouterOutlet]',
  standalone: false,
})
export class RouterOutletDirective implements OnDestroy {
  private readonly _subscription: Subscription | undefined;

  constructor(
    private readonly routerOutlet: RouterOutlet,
    private readonly pageContextService: PageContextService,
  ) {
    this._subscription = routerOutlet.activateEvents.subscribe((page: IPage) =>
      pageContextService.registerPage(page),
    );
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
