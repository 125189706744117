import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from 'angular-auth-oidc-client';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule.forRoot({
      config: {
        authority: `${process.env['OIDC_PROVIDER_URL']}/api/oidc`,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'web-frontend',
        scope:
          'openid offline_access profile phone email organizations subscriptions roles marketer_questionnaire',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        triggerRefreshWhenIdTokenExpired: true,
        ignoreNonceAfterRefresh: true,
        renewTimeBeforeTokenExpiresInSeconds: 3 * 60,
      },
    }),
  ],
})
export class AuthCoreModule {}
