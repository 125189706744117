import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Feedback } from '@prlw/core/feedback/feedback.entity';
import { FeedbackController } from '@prlw/core/feedback/feedback.controller';
import { FeedbackStatus } from '@prlw/core/feedback/feedback-status.enum';
import { FileUploadService } from '@prlw/core/feedback/file-upload.service';

@Component({
  selector: 'prlw-feedback-page',
  templateUrl: './feedback.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FeedbackContainer implements OnDestroy {
  @Output() public readonly closeModal = new EventEmitter<void>();
  public feedbackStatus$: Observable<FeedbackStatus>;
  public savedData: Feedback | null;
  public lastStatus: FeedbackStatus;
  private readonly feedbackStatusSub: Subscription;

  constructor(
    private readonly feedbackController: FeedbackController,
    private readonly fileUploadService: FileUploadService,
  ) {
    this.feedbackStatus$ = this.feedbackController.feedbackStatus$;
    this.savedData = null;
    this.lastStatus = FeedbackStatus.INIT;

    this.feedbackStatusSub = this.feedbackStatus$.subscribe((status) => {
      if (status === FeedbackStatus.SENT || status === FeedbackStatus.ERROR) {
        this.lastStatus = status;
      }
      if (status === FeedbackStatus.SENT) {
        this.fileUploadService.files$.next({});
      }
    });
  }

  public ngOnDestroy(): void {
    this.feedbackController.setInit();
    this.feedbackStatusSub.unsubscribe();
  }

  public isInit(status: FeedbackStatus): boolean {
    return status === FeedbackStatus.INIT;
  }

  public isSending(status: FeedbackStatus): boolean {
    return status === FeedbackStatus.SENDING;
  }

  public isSent(status: FeedbackStatus): boolean {
    return status === FeedbackStatus.SENT;
  }

  public isError(status: FeedbackStatus): boolean {
    return status === FeedbackStatus.ERROR;
  }

  public send(data: Feedback): void {
    this.savedData = this.savedData ?? data;
    this.feedbackController.sendFeedback(this.savedData);
  }

  public exit(): void {
    this.closeModal.emit();
  }
}
