import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { AccessPeriod, Profile } from '@prlw/core/profile/profile.entity';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prlw-status-for-icon',
  templateUrl: './status-for-icon.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StatusForIconContainer {
  public readonly accessPeriod$: Observable<AccessPeriod>;

  constructor(private readonly profileController: ProfileController) {
    this.accessPeriod$ = profileController.profile$.pipe(
      map(
        (profile: Profile): AccessPeriod => ({
          accessInDays: profile.totalDays,
          expireDays: profile.expiresDay,
          isAlertActive: profile.isAlertActive,
        }),
      ),
    );
  }
}
