import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'prlw-file-upload-overlay-ui',
  templateUrl: './file-upload-overlay.component.html',
  styleUrls: ['./file-upload-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FileUploadOverlayComponent {
  @Output() public readonly closeOverlay = new EventEmitter();

  public onCloseOverlay(): void {
    this.closeOverlay.emit();
  }
}
