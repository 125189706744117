import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'prlw-license-docs-alert',
  templateUrl: './license-docs-alert.component.html',
  styleUrls: ['./license-docs-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LicenseDocsAlertComponent {
  @Input() public date: string = ' 12 декабря 2021 ';
}
