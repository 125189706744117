import { NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TARIFFS_GATEWAY } from '@prlw/core/tariffs/tariffs.gateway';
import { HttpTariffsGateway } from './tariffs.gateway';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: TARIFFS_GATEWAY, useClass: HttpTariffsGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class TariffsDataModule {}
