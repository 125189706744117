import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeedbackOverlayService } from '../../../features/feedback/feedback-overlay.service';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';
import {
  FullscreenOverlayContainer,
  OverlayContainer,
} from '@angular/cdk/overlay';
import { AuthStateProvider } from '@prlw/core/auth/auth-state.provider';
import { MobileOverlayService } from '@prlw/libs/mobile-overlay/mobile-overlay.service';
import { MobileMenuContainer } from '@prlw/common/mobile-menu/mobile-menu.container';
import { FeedbackMobileComponent } from 'src/app/features/feedback/feedback-mobile.component';

@Component({
  selector: 'prlw-header-primary',
  templateUrl: './header-primary.component.html',
  styleUrls: ['./header-primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  standalone: false,
})
export class HeaderPrimaryComponent {
  constructor(
    private readonly feedbackService: FeedbackOverlayService,
    private readonly authStateProvider: AuthStateProvider,
    private readonly mobileOverlayService: MobileOverlayService,
    private readonly responsiveService: ResponsiveService,
  ) {}

  xsmall$ = this.responsiveService.xsmall$;
  isAuthorized$ = this.authStateProvider.hasRefreshToken$;

  public showFeedback(): void {
    if (this.xsmall$.getValue()) {
      this.mobileOverlayService.show(FeedbackMobileComponent);
      return;
    }
    this.feedbackService.show();
  }

  handleMobileMenuSandwichButtonClick() {
    this.mobileOverlayService.show(MobileMenuContainer);
  }
}
