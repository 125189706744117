import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import pluralize from '@prlw/libs/pluralize/pluralize';
import { LicenseStatus } from '@prlw/core/profile/profile-license.entity';

export type LicensePro = Pick<LicenseStatus, 'accessInDate' | 'accessInDays'>;

@Component({
  selector: 'prlw-license-card-pro',
  templateUrl: './license-card-pro.component.html',
  styleUrls: ['./license-card-pro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LicenseCardProComponent {
  @Input() public accessToPro!: LicensePro;

  public pluralize(value: number): string {
    const DAY = 24;
    const HOUR = 60;
    const TIME = Math.floor(value / (HOUR * HOUR * DAY));
    return pluralize(TIME, ['день', 'дня', 'дней']);
  }
}
