import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-feedback-help-button',
  imports: [CommonModule, IconsModule, ResponsiveDirective],
  templateUrl: './feedback-help-button.component.html',
  styleUrl: './feedback-help-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackHelpButtonComponent {
  @Output() eventShowFeedbackDialog = new EventEmitter<void>();

  constructor(private responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;
}
