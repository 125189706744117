import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccessPeriod } from '@prlw/core/profile/profile.entity';

const CIRCLE_LENGTH = 107;

@Component({
  selector: 'prlw-status-for-icon-ui',
  templateUrl: './status-for-icon.component.html',
  styleUrls: ['./status-for-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StatusForIconComponent {
  @Input() public accessPeriod!: AccessPeriod;
  public circleLength = CIRCLE_LENGTH;

  public get calculateRestDemoAccess(): number {
    return (
      (this.circleLength * this.accessPeriod.expireDays) /
        this.accessPeriod.accessInDays -
      this.circleLength
    );
  }
}
