import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Optional,
  Inject,
} from '@angular/core';
import { LicenseCard, LicenseType } from '@prlw/core/profile/summary.entity';
import { TabsComponent } from '@prlw/libs/tabs/tabs.component';

@Component({
  selector: 'prlw-profile-summary-license-card',
  templateUrl: './profile-summary-license-card.component.html',
  styleUrls: ['./profile-summary-license-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProfileSummaryLicenseCardComponent {
  @Input() public profileLicense: LicenseCard | null = null;

  constructor(@Optional() @Inject(TabsComponent) public tabs: TabsComponent) {}

  public navigate(): void {
    this.tabs.setActiveTab('license');
  }

  public get isLight(): boolean {
    return this.profileLicense?.licenceType === LicenseType.PROLEUM_LIGHT;
  }

  public get isPro(): boolean {
    return this.profileLicense?.licenceType === LicenseType.PROLEUM_PRO;
  }
}
