import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Feedback } from '@prlw/core/feedback/feedback.entity';

@Component({
  selector: 'prlw-feedback-error-ui',
  templateUrl: './feedback-error.component.html',
  styleUrls: ['./feedback-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FeedbackErrorComponent {
  @Input() public isLoading: boolean = false;
  @Output() public readonly closeModal = new EventEmitter();
  @Output() public readonly sendFeedback = new EventEmitter<Feedback>();

  public exit(): void {
    this.closeModal.emit();
  }

  public send(): void {
    this.sendFeedback.emit({
      themeInput: '',
      messageInput: '',
      attachedFiles: [],
    });
  }
}
