import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MarketTime } from '@prlw/core/market-time/market-time.entity';
import { MarketTimeController } from '@prlw/core/market-time/market-time.controller';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-market-time',
  templateUrl: './market-time.container.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MarketTimeContainer implements OnDestroy {
  public readonly marketTime$: Observable<MarketTime>;

  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  xsmall$ = this.responsiveService.xsmall$;

  constructor(
    private readonly marketTimeController: MarketTimeController,
    private readonly router: Router,
    private responsiveService: ResponsiveService,
  ) {
    this.marketTime$ = this.route$.pipe(
      switchMap((route) =>
        route.includes('/futures')
          ? marketTimeController.timerFutures$
          : marketTimeController.timerSpot$,
      ),
    );

    router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        this.route$.next(event.url);
      });
  }

  route$ = new BehaviorSubject<string>(this.router.url);

  get tooltipText() {
    if (this.router.url.includes('/futures'))
      return 'В секции «Срочный рынок» торги стартуют в 11:00 (GMT +3) и заканчиваются в 16:00 (GMT+3) с понедельника по пятницу.';
    return 'В секции «Нефтепродукты» торги стартуют в 11:00 (GMT +3) и заканчиваются в 13:00 (GMT+3) с понедельника по пятницу.';
  }
}
