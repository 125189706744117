import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LicenseHistory } from '@prlw/core/profile/profile-license.entity';
import { Observable } from 'rxjs';
import { ProfileController } from '@prlw/core/profile/profile.controller';

@Component({
  selector: 'prlw-license-history',
  templateUrl: './license-history.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LicenseHistoryContainer {
  public history$: Observable<LicenseHistory[] | null>;

  constructor(private readonly profileController: ProfileController) {
    this.history$ = profileController.licenseHistory$;
  }
}
