import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpMarketTimeGateway } from '@prlw/data/market-time/market-time-gateway';
import { MARKET_TIME_GATEWAY } from '@prlw/core/market-time/market-time-gateway.interface';
import { MARKET_TIME_DATA_CONFIG } from '@prlw/data/market-time/market-time-data-config.token';

export type MarketTimeDataConfig = {
  apiPrefix: string;
};

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: MARKET_TIME_GATEWAY,
      useClass: HttpMarketTimeGateway,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class MarketTimeDataModule {
  public static forRoot(
    config: MarketTimeDataConfig,
  ): ModuleWithProviders<MarketTimeDataModule> {
    return {
      ngModule: MarketTimeDataModule,
      providers: [
        {
          provide: MARKET_TIME_DATA_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
