import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { TitleLabelContainer } from '../header/title-label/title-label.container';
import { IconsModule } from '@prlw/libs/icons/icons.module';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MobileButtonComponent } from '../mobile-button/mobile-button.component';
import { TitleLogoContainer } from '../header/title-logo/title-logo.container';
import { TitleProfileContainer } from '../header/title-profile/title-profile.container';
import { MobileOverlayService } from '@prlw/libs/mobile-overlay/mobile-overlay.service';
import { FeedbackMobileComponent } from 'src/app/features/feedback/feedback-mobile.component';

export type TMobileSubmenuItem = {
  icon: string;
  active: boolean;
  title: string;
  routerLink: string;
};

@Component({
  selector: 'prlw-mobile-menu-ui',
  imports: [
    CommonModule,
    TitleLabelContainer,
    IconsModule,
    MobileButtonComponent,
    TitleLogoContainer,
    TitleProfileContainer,
  ],
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnInit, OnDestroy {
  @Input() items: TMobileSubmenuItem[] | null = [];

  @Output() eventClose = new EventEmitter<void>();
  @Output() eventMobileSubmenuItemClick =
    new EventEmitter<TMobileSubmenuItem>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly renderer: Renderer2,
    private readonly mobileOverlayService: MobileOverlayService,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'overflow-hidden');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'overflow-hidden');
  }

  handleMobileButtonFeedbackClick() {
    this.mobileOverlayService.show(FeedbackMobileComponent);
  }
}
