import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CommonPaneComponent,
  TCommonPaneAppearance,
} from '@prlw/common/common-pane/common-pane.component';

@Component({
  selector: 'prlw-title-logo-ui',
  imports: [CommonPaneComponent],
  templateUrl: './title-logo.component.html',
  styleUrl: './title-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLogoComponent {
  @Input() appearance: TCommonPaneAppearance = null;
  @Input() svgName: string | null = null;
}
