import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fileBrowser } from '../file-upload/file-upload.utils';
import { FileUpload } from '@prlw/core/profile/file-upload.entity';
import { Observable } from 'rxjs';

@Component({
  selector: 'prlw-attachments-files-ui',
  templateUrl: './attachments-files.component.html',
  styleUrls: ['./attachments-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AttachmentsFilesComponent {
  @ViewChild('inputElement', { static: true }) public inputFile!: ElementRef;
  @Input() public files$: Observable<FileUpload[]> | null = null;
  @Input() public status$: Observable<string | null> | null = null;
  @Output() public readonly deleteFile = new EventEmitter();
  @Output() public readonly addFile = new EventEmitter();
  @Output() public readonly sendFile = new EventEmitter();
  @Output() public readonly sendComment = new EventEmitter();
  public commentaries?: string;

  public onDelete(index: number): void {
    this.deleteFile.emit(index);
  }

  public onSendFile(): void {
    if (this.commentaries) {
      this.sendComment.emit(this.commentaries);
    }
    this.sendFile.emit();
  }

  public onAddFile(): void {
    this.inputFile.nativeElement.click();
  }

  public onFileBrowser(event: Event): void {
    this.addFile.emit(fileBrowser(event));
  }
}
