import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleService } from '@prlw/common/page/page-title.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'prlw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppComponent {
  constructor(
    private readonly pageTitleService: PageTitleService,
    private readonly googleTagManagerService: GoogleTagManagerService,
  ) {
    pageTitleService.setPageTitle('');
    googleTagManagerService.addGtmToDom();
  }
}
