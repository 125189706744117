import { NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpCalendarGateway } from './calendar.gateway';
import { CALENDAR_GATEWAY } from '@prlw/core/calendar/calendar.gateway';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: CALENDAR_GATEWAY, useClass: HttpCalendarGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CalendarDataModule {}
