import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FileUploadController } from '@prlw/core/profile/file-upload.controller';
import { Observable } from 'rxjs';
import { FileUpload } from '@prlw/core/profile/file-upload.entity';

@Component({
  selector: 'prlw-attachments-files',
  templateUrl: './attachments-files-container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AttachmentsFilesContainer {
  public files$: Observable<FileUpload[]>;
  public status$: Observable<string | null>;

  constructor(private readonly filesController: FileUploadController) {
    this.files$ = this.filesController.files$;
    this.status$ = this.filesController.sendToServerStatus$;
  }

  public addFile(files: File[]): void {
    this.filesController.addFiles(files);
  }

  public deleteFile(index: number): void {
    this.filesController.selectFileToDelete(index);
  }

  public sendComment(comment: string): void {
    this.filesController.setComment(comment);
  }

  public sendFile(): void {
    this.filesController.sendDocument();
  }
}
