import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TitleLogoComponent } from './title-logo.component';
import { PageContextService } from '@prlw/common/page/page-context.service';
import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TCommonPaneAppearance } from '@prlw/common/common-pane/common-pane.component';

@Component({
  selector: 'prlw-title-logo',
  imports: [CommonModule, TitleLogoComponent],
  templateUrl: './title-logo.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLogoContainer {
  @Input() appearance: TCommonPaneAppearance = null;

  svgName$ = this.pageContextService.pageContext$.pipe(
    map((context) => context.titleSvgName),
  );

  constructor(private readonly pageContextService: PageContextService) {}
}
