import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BUY_PRODUCT_DATA_CONFIG } from '@prlw/data/buy-product/buy-product-data-config.token';
import { BUY_PRODUCT_GATEWAY } from '@prlw/core/buy-product/buy-product-gateway.token';
import { HttpBuyProductGateway } from '@prlw/data/buy-product/buy-product.gateway';

export type BuyProductDataConfig = {
  apiPrefix: string;
};

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: BUY_PRODUCT_GATEWAY, useClass: HttpBuyProductGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class BuyProductDataModule {
  public static forRoot(
    config: BuyProductDataConfig,
  ): ModuleWithProviders<BuyProductDataModule> {
    return {
      ngModule: BuyProductDataModule,
      providers: [{ provide: BUY_PRODUCT_DATA_CONFIG, useValue: config }],
    };
  }
}
