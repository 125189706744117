import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HttpUserSettingsGateway } from '@prlw/data/user-settings/user-settings.gateway';
import { USER_SETTINGS_GATEWAY } from '@prlw/core/user-settings/user-settings-gateway.token';
import { USER_SETTINGS_DATA_CONFIG } from '@prlw/data/user-settings/user-settings-data-config.token';

export type UserSettingsDataConfig = {
  apiPrefix: string;
};

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: USER_SETTINGS_GATEWAY, useClass: HttpUserSettingsGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class UserSettingsDataModule {
  public static forRoot(
    config: UserSettingsDataConfig,
  ): ModuleWithProviders<UserSettingsDataModule> {
    return {
      ngModule: UserSettingsDataModule,
      providers: [{ provide: USER_SETTINGS_DATA_CONFIG, useValue: config }],
    };
  }
}
