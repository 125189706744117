import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UserListTabComponent } from './user-list-tab.component';
import { PROFILE_GATEWAY } from '@prlw/core/profile/profile-gateway.token';
import {
  ProfileGateway,
  TGetUserListItems,
} from '@prlw/core/profile/profile-gateway.interface';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  catchError,
  from,
  map,
  merge,
  switchMap,
} from 'rxjs';

export type TParams = {
  str?: string;
  page: number;
  sortBy: string;
  sortOrder: string;
  includeHiddenFromUserList: boolean;
};

export type TActions = {
  deleteAction: () => Promise<void>;
  hideUserFromUserListAction: (hiddenFromUserList: boolean) => Promise<void>;
  updateProAction: (expiredAt: Date | null) => Promise<void>;
};

@Component({
  selector: 'prlw-user-list-tab',
  imports: [CommonModule, UserListTabComponent],
  templateUrl: './user-list-tab.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListTabContainer {
  constructor(@Inject(PROFILE_GATEWAY) private gateway: ProfileGateway) {}

  params$ = new BehaviorSubject<TParams>({
    page: 1,
    sortBy: 'fullName',
    sortOrder: 'asc',
    includeHiddenFromUserList: false,
  });

  deleteAction$ = new Subject<TGetUserListItems>();
  hideUserFromUserListAction$ = new Subject<TGetUserListItems>();
  updateProAction$ = new Subject<TGetUserListItems>();

  items$ = this.params$.pipe(
    switchMap(({ str, page, sortBy, sortOrder, includeHiddenFromUserList }) =>
      merge(
        from(
          this.gateway.getUserListItems(
            str,
            page,
            sortBy,
            sortOrder,
            includeHiddenFromUserList,
          ),
        ).pipe(catchError(() => EMPTY)),
        this.deleteAction$.pipe(catchError(() => EMPTY)),
        this.hideUserFromUserListAction$.pipe(catchError(() => EMPTY)),
        this.updateProAction$.pipe(catchError(() => EMPTY)),
      ).pipe(
        map((items) => ({
          ...items,
          result: items.result.map((item) => ({
            ...item,
            deleteAction: async () =>
              this.gateway
                .deleteUserByObjectId(
                  item._id,
                  str,
                  page,
                  sortBy,
                  sortOrder,
                  includeHiddenFromUserList,
                )
                .then((res) => {
                  this.deleteAction$.next(res);
                }),
            hideUserFromUserListAction: async (hiddenFromUserList: boolean) =>
              this.gateway
                .hideUserFromUserListByObjectId(
                  item._id,
                  hiddenFromUserList,
                  str,
                  page,
                  sortBy,
                  sortOrder,
                  includeHiddenFromUserList,
                )
                .then((res) => {
                  this.hideUserFromUserListAction$.next(res);
                }),
            updateProAction: async (expiredAt: Date | null) =>
              this.gateway
                .updateProByObjectId(
                  item._id,
                  expiredAt,
                  str,
                  page,
                  sortBy,
                  sortOrder,
                  includeHiddenFromUserList,
                )
                .then((res) => {
                  this.updateProAction$.next(res);
                }),
          })),
        })),
        map((items) => ({
          ...items,
          result: items.result.map((item, index) => ({
            ...item,
            num: ((items?.page ?? 1) - 1) * 150 + index + 1,
          })),
        })),
      ),
    ),
  );

  handleChangeParams(event: TParams) {
    this.params$.next(event);
  }
}
