import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'prlw-text-tag',
  imports: [CommonModule],
  templateUrl: './text-tag.component.html',
  styleUrl: './text-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextTagComponent {
  @Input() text: string | null = null;
  @Input() size: 'small' | 'medium' = 'small';
}
