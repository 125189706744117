import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'prlw-file-upload-overlay-container',
  templateUrl: './file-upload-overlay-container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FileUploadOverlayContainer {
  public readonly closeOverlay = new EventEmitter<void>();

  public onCloseOverlay(): void {
    this.closeOverlay.emit();
  }
}
