import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subscription, from, fromEvent, merge, Observable } from 'rxjs';
import { delay, first, mergeMap, repeat } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

export function initActivity(initializer: UserActivityService): () => void {
  return () => initializer.init();
}

const SENDING_FREQUENCY = 600000;

@Injectable({
  providedIn: 'root',
})
export class UserActivityService implements OnDestroy {
  private activityEvents$!: Observable<Event>;
  private actions$!: Observable<Event>;
  private subscription$!: Subscription;

  constructor(
    private readonly _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient,
  ) {}

  public init(): void {
    return this.startWatching();
  }

  public startWatching(): void {
    this._ngZone.runOutsideAngular(() => {
      this.activityEvents$ = merge(fromEvent(this.document, 'mousemove'));
      this.actions$ = from(this.activityEvents$);
      this.subscription$ = this.actions$
        .pipe(
          first(),
          mergeMap(() =>
            this.http.post(
              `${process.env['CUSTOMERS_API_URL']}/api/user/last-activity`,
              {
                lastActivity: new Date(),
              },
            ),
          ),
          delay(SENDING_FREQUENCY),
          repeat(),
        )
        .subscribe();
    });
  }

  public ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
