import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { Subscription } from 'rxjs';
import { Organization } from '@prlw/core/profile/organization.entity';

@Component({
  selector: 'prlw-profile-organization-tab',
  templateUrl: './profile-organization-tab.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProfileOrganizationTabContainer implements OnDestroy {
  // undefined = loading, null = no organization
  public organization: Organization | null | undefined;
  public areRegulationsSigned?: boolean;
  private readonly profileSub: Subscription;

  constructor(
    profileController: ProfileController,
    changeDetector: ChangeDetectorRef,
  ) {
    this.profileSub = profileController.profile$.subscribe((profile) => {
      this.organization = profile.organization ?? null;
      this.areRegulationsSigned = profile.areRegulationsSigned;
      changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.profileSub.unsubscribe();
  }
}
