import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ICONS_CONFIG } from './icons-config.token';

export type IconsConfig = {
  baseUrl: string;
};

@NgModule({
  declarations: [IconComponent],
  exports: [IconComponent],
  imports: [CommonModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class IconsModule {
  public static forRoot(config: IconsConfig): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: [{ provide: ICONS_CONFIG, useValue: config }],
    };
  }

  public static forFeature(): ModuleWithProviders<IconsModule> {
    return { ngModule: IconsModule };
  }
}
