import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-feedback-gratitude-ui',
  templateUrl: './feedback-gratitude.component.html',
  styleUrls: ['./feedback-gratitude.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FeedbackGratitudeComponent {
  @Output() public readonly closeModal = new EventEmitter<string>();

  public exit(): void {
    this.closeModal.emit();
  }

  constructor(private readonly responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;
}
