import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { PersonalData } from '@prlw/core/profile/profile.entity';
import { ProfileDataConfig } from './profile-data.module';
import {
  PersonalDataGetResponse,
  SummaryGetResponse,
} from './api-types/get-profile.api';
import {
  ProfileGateway,
  TGetUserListItems,
} from '@prlw/core/profile/profile-gateway.interface';
import { PROFILE_DATA_CONFIG } from '@prlw/data/profile/profile-data-config.token';
import { catchError, map } from 'rxjs/operators';
import {
  License,
  LicenseHistory,
  LicenseStatus,
} from '@prlw/core/profile/profile-license.entity';
import { SummaryInfo } from '@prlw/core/profile/summary.entity';
import { LicenseDocuments } from '@prlw/core/profile/profile-license-documents.entity';
import {
  InvoiceData,
  InvoiceDataResponse,
} from '@prlw/core/profile/invoice.entity';

const accessInDays = 19;

export type ConfirmationResult =
  | {
      codesWasSent: boolean;
      timeToNextSend: number;
    }
  | { success: true }
  | { success: false };

export type UserNotFoundError = {
  message?: string;
};

const HISTORY: LicenseHistory[] = [
  {
    startPeriod: '01.06.2020',
    endPeriod: '01.07.2020',
    type: 'Бонусная',
    invoiceStatus: 'Оплачен',
    documentInvoice: 'Счёт',
    documentAct: 'Акт об оказанных услугах',
  },
  {
    startPeriod: '01.01.2020',
    endPeriod: '01.06.2020 ',
    type: 'Платная',
    invoiceStatus: 'Выставлен',
    documentInvoice: 'Счёт',
  },
  {
    startPeriod: '01.06.2020',
    endPeriod: '01.07.2020',
    type: 'Бонусная',
    invoiceStatus: 'Оплачен',
    documentInvoice: 'Счёт',
    documentAct: 'Акт об оказанных услугах',
  },
];

const LICENSE_STATUS: LicenseStatus = {
  type: License.Pro,
  active: true,
  accessInDate: '23 декабря',
  accessInDays,
};

const INVOICE_DOCUMENTS: LicenseDocuments[] = [
  {
    documentId: '52242d2ffb55f8',
    description: 'Договор-счёт на сумму 16 875 ₽ от 12 декабря 2021',
    comment: '',
  },
  {
    documentId: '88345f23f440c1f4',
    description: 'Договор-счёт на сумму 93 500 ₽ от 11 декабря 2021',
    comment: '',
  },
  {
    documentId: '1d85ad27de0c322e7193dff31c356a8732',
    description: 'Договор-счёт на сумму 17 500 ₽ от 11 декабря 2021',
    comment: '',
  },
];

@Injectable({
  providedIn: 'root',
})
export class HttpProfileGateway implements ProfileGateway {
  constructor(
    @Inject(PROFILE_DATA_CONFIG) private readonly config: ProfileDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getLicenseHistory(): Observable<LicenseHistory[]> {
    return of(HISTORY);
  }

  public getLicenseStatus(): Observable<LicenseStatus> {
    return of(LICENSE_STATUS);
  }

  public getSummaryInfo(): Observable<SummaryInfo> {
    return this.http.get<SummaryGetResponse>(
      `${this.config.apiPrefix}/get-summary-info`,
    );
  }

  public getPersonalData(): Observable<PersonalData> {
    return this.http.get<PersonalDataGetResponse>(
      `${this.config.apiPrefix}/data`,
    );
  }

  public setPersonalData(data: PersonalData): Observable<PersonalData> {
    return this.http.put<PersonalDataGetResponse>(
      `${this.config.apiPrefix}/set-data`,
      data,
    );
  }

  public sendInvoiceData(data: InvoiceData): Observable<InvoiceDataResponse> {
    return this.http.post<InvoiceDataResponse>(
      `${this.config.apiPrefix}/send-invoice-data`,
      data,
    );
  }

  public takeSmsCode(
    email: string,
  ): Observable<ConfirmationResult | UserNotFoundError> {
    return this.http
      .post<
        ConfirmationResult | UserNotFoundError
      >('/api/registration/email-code', { email }, { headers: { SkipAuth: 'true' } })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({ message: error.error.message }),
        ),
      );
  }

  public codeConfirm(data: {
    code: string;
    email: string;
  }): Observable<ConfirmationResult | UserNotFoundError> {
    return this.http
      .post<
        ConfirmationResult | UserNotFoundError
      >('/api/registration/email-activation', data, { headers: { SkipAuth: 'true' } })
      .pipe(
        catchError((error: HttpErrorResponse) =>
          of({ message: error.error.message }),
        ),
        map((response) => {
          if ('token' in response) {
            return {
              success: true,
            };
          }
          return {
            success: false,
          };
        }),
      );
  }

  public getInvoiceDocuments(): Observable<LicenseDocuments[]> {
    return of(INVOICE_DOCUMENTS);
  }

  getUserListItems(
    str = '',
    page = 1,
    sortBy = 'fullName',
    sortOrder = 'asc',
    includeHiddenFromUserList = false,
  ) {
    return firstValueFrom(
      this.http.get<TGetUserListItems>(
        `/api/user-list?str=${str}&page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&includeHiddenFromUserList=${includeHiddenFromUserList}`,
      ),
    );
  }

  deleteUserByObjectId(
    objectId: string,
    str = '',
    page = 1,
    sortBy = 'fullName',
    sortOrder = 'asc',
    includeHiddenFromUserList = false,
  ) {
    return firstValueFrom(
      this.http.delete<TGetUserListItems>(
        `/api/user-list/user/${objectId}?str=${str}&page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&includeHiddenFromUserList=${includeHiddenFromUserList}`,
      ),
    );
  }

  hideUserFromUserListByObjectId(
    objectId: string,
    hiddenFromUserList: boolean,
    str = '',
    page = 1,
    sortBy = 'fullName',
    sortOrder = 'asc',
    includeHiddenFromUserList = false,
  ) {
    return firstValueFrom(
      this.http.post<TGetUserListItems>(
        `/api/user-list/user/hide/${objectId}?str=${str}&page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&includeHiddenFromUserList=${includeHiddenFromUserList}`,
        { hiddenFromUserList },
      ),
    );
  }

  updateProByObjectId(
    objectId: string,
    expiredAt: Date,
    str = '',
    page = 1,
    sortBy = 'fullName',
    sortOrder = 'asc',
    includeHiddenFromUserList = false,
  ) {
    return firstValueFrom(
      this.http.post<TGetUserListItems>(
        `/api/user-list/user/update-pro/${objectId}?str=${str}&page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}&includeHiddenFromUserList=${includeHiddenFromUserList}`,
        { expiredAt },
      ),
    );
  }
}
