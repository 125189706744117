import { Injectable } from '@angular/core';
import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';
import { NotificationService } from '@prlw/infrastructure/errors/notification/notification.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { debounceTime, first, Subject, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LogoutController {
  constructor(
    private readonly storage: JwtStorage,
    private readonly notificationService: NotificationService,
    private readonly oidcSecurityService: OidcSecurityService,
  ) {
    this.logout$
      .pipe(
        debounceTime(300),
        switchMap(() => this.oidcSecurityService.logoffAndRevokeTokens()),
      )
      .pipe(first())
      .subscribe();
  }

  private readonly logout$ = new Subject<void>();

  public logout(): void {
    this.storage.dropTokens();
    this.logout$.next();
  }

  public logoutWithError(e: PrlwAuthError) {
    this.logout();
    this.notificationService.notify(e);
  }
}
