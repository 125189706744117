import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FileUploadOverlayService } from '../file-upload-overlay/file-upload-overlay.service';

@Component({
  selector: 'prlw-license-header',
  templateUrl: './license-header.component.html',
  styleUrls: ['./license-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LicenseHeaderComponent {
  constructor(private readonly overlay: FileUploadOverlayService) {}

  public onOpenOverlay(): void {
    this.overlay.show();
  }
}
