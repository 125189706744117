import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileController } from '@prlw/core/profile/profile.controller';
import { Profile } from '@prlw/core/profile/profile.entity';
import { Router } from '@angular/router';
import { LogoutController } from '@prlw/core/auth/logout/logout.controller';

@Component({
  selector: 'prlw-demo-access-page',
  templateUrl: './demo-access.container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DemoAccessContainer {
  public readonly profile$: Observable<Profile>;
  @Output() public readonly closeModal = new EventEmitter<void>();

  constructor(
    private readonly profileController: ProfileController,
    private readonly logoutController: LogoutController,
    private readonly router: Router,
  ) {
    this.profile$ = profileController.profile$;
  }

  async handleEventGoToProfilePage() {
    this.closeModal.emit();
    await this.router.navigate(['profile']);
  }

  handleEventLogout() {
    this.logoutController.logout();
  }
}
