import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';

export type TCommonPaneAppearance = 'transparent' | 'white' | 'color' | null;

@Component({
  selector: 'prlw-common-pane',
  imports: [ResponsiveDirective],
  templateUrl: './common-pane.component.html',
  styleUrl: './common-pane.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPaneComponent {
  @Input() appearance: TCommonPaneAppearance = null;
}
