import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'prlw-layout-minimal',
  templateUrl: './layout-minimal.component.html',
  styleUrls: ['./layout-minimal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LayoutMinimalComponent {}
