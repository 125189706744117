import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { LicenseDocuments } from '@prlw/core/profile/profile-license-documents.entity';
import { DocumentInfo } from '@prlw/core/profile/file-upload.entity';

type SelectData = {
  title: string;
  documentId: string | null;
};

@Component({
  selector: 'prlw-uploaded-documents-ui',
  templateUrl: './uploaded-documents.component.html',
  styleUrls: ['./uploaded-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UploadedDocumentsComponent implements OnInit {
  @Input() public documents?: LicenseDocuments[];
  @Output() public readonly sendDocument = new EventEmitter();
  public isCollapsed: boolean = false;
  public defaultText: string = 'Моего договора нет в списке';
  public selectItem: SelectData = { title: this.defaultText, documentId: null };

  public onOpen(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public onClose(): void {
    this.isCollapsed = false;
  }

  public onSelect(index?: string, documentId?: string): void {
    this.selectItem.title = this.defaultText;
    if (index) {
      this.selectItem.title = index;
    }
    this._sendDocumentInfo(documentId);
  }

  private _sendDocumentInfo(documentId?: string): void {
    const documentInfo: DocumentInfo = {
      title: this.selectItem.title,
      documentId: documentId ?? null,
    };
    this.sendDocument.emit(documentInfo);
  }

  private _initDefaultDocument(): void {
    if (!this.documents) {
      return;
    }
    if (this.documents.length) {
      this.selectItem = {
        title: this.documents[0].description,
        documentId: this.documents[0].documentId,
      };
    }
    this.sendDocument.emit(this.selectItem);
  }

  public ngOnInit(): void {
    this._initDefaultDocument();
  }
}
