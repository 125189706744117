import { Inject, Injectable } from '@angular/core';
import { PersonalData, Profile } from './profile.entity';
import { BehaviorSubject, Observable } from 'rxjs';
import { PROFILE_GATEWAY } from '@prlw/core/profile/profile-gateway.token';
import { ProfileGateway } from '@prlw/core/profile/profile-gateway.interface';
import { map, shareReplay } from 'rxjs/operators';
import {
  LicenseHistory,
  LicenseStatus,
} from '@prlw/core/profile/profile-license.entity';
import { SummaryInfo } from '@prlw/core/profile/summary.entity';
import {
  ConfirmationResult,
  UserNotFoundError,
} from '@prlw/data/profile/profile.gateway';
import { LicenseDocuments } from '@prlw/core/profile/profile-license-documents.entity';
import {
  InvoiceData,
  InvoiceDataResponse,
} from '@prlw/core/profile/invoice.entity';
import { UserDataService } from '../customers/oidc/user-data.service';

const BUFFER_SIZE = 1;

export enum ConfirmStatus {
  INIT = 'Init',
  CONFIRM_BY_CODE = 'ConfirmByCode',
  CONFIRMED = 'Confirmed',
  ERROR = 'Error',
}

@Injectable({
  providedIn: 'root',
})
export class ProfileController {
  public readonly profile$: Observable<Profile>;
  public readonly licenseHistory$: Observable<LicenseHistory[]>;
  public readonly licenseStatus$: Observable<LicenseStatus>;
  public readonly summaryInfo$: Observable<SummaryInfo>;
  public confirmEmailStatus$: Observable<ConfirmStatus>;
  private readonly _confirmEmailStatus$: BehaviorSubject<ConfirmStatus>;
  public readonly invoiceDocuments$: Observable<LicenseDocuments[]>;
  public readonly isPro$: Observable<boolean>;

  constructor(
    @Inject(PROFILE_GATEWAY) private readonly profileGateway: ProfileGateway,
    private readonly userDataService: UserDataService,
  ) {
    this.invoiceDocuments$ = this.profileGateway.getInvoiceDocuments();
    this.licenseHistory$ = this.profileGateway.getLicenseHistory();
    this.licenseStatus$ = this.profileGateway.getLicenseStatus();
    this._confirmEmailStatus$ = new BehaviorSubject<ConfirmStatus>(
      ConfirmStatus.INIT,
    );
    this.confirmEmailStatus$ = this._confirmEmailStatus$.asObservable();
    this.profile$ = userDataService.userData$.pipe(
      map(
        ({
          name,
          created_at,
          email,
          phone_number,
          subscriptions,
          organizations,
          marketer_questionnaire,
          roles,
        }) => {
          const proSubscription = subscriptions.find(
            (item) => item.name === 'pro',
          );
          const organization = organizations[0]?.organization;
          return {
            fullName: name,
            organization: organization
              ? {
                  actualAddress: organization.factAddress ?? '',
                  inn: organization.inn,
                  kpp: organization.kpp ?? '',
                  legalAddress: organization.legalAddress ?? '',
                  name: organization.name,
                  okpo: organization.okpo ?? '',
                  phone: organization.phoneNumber ?? '',
                  headOfOrganization: organization.director ?? '',
                }
              : undefined,
            phone: phone_number,
            email,
            mode: proSubscription ? 'pro' : 'light',
            createdAt: created_at,
            expiredAt: proSubscription?.expiredDate ?? null,
            roleEditor: roles.includes('admin'),
            isDemo: false,
            expiresDay: 999,
            isAlertActive: false,
            totalDays: 999,
            marketerQuestionnaire: marketer_questionnaire,
          };
        },
      ),
    );
    this.summaryInfo$ = this.profileGateway
      .getSummaryInfo()
      .pipe(shareReplay(BUFFER_SIZE));
    this.isPro$ = this.profile$.pipe(map(({ mode }) => mode === 'pro'));
  }

  public getPersonalData(): Observable<PersonalData> {
    return this.profileGateway.getPersonalData().pipe(shareReplay(BUFFER_SIZE));
  }

  public setStatus(status: ConfirmStatus): void {
    this._confirmEmailStatus$.next(status);
  }

  public takeCode(
    email: string,
  ): Observable<ConfirmationResult | UserNotFoundError> {
    return this.profileGateway.takeSmsCode(email);
  }

  public codeConfirm(data: {
    code: string;
    email: string;
  }): Observable<ConfirmationResult | UserNotFoundError> {
    return this.profileGateway.codeConfirm(data);
  }

  public setPersonalData(data: PersonalData): Observable<PersonalData> {
    return this.profileGateway.setPersonalData(data);
  }

  public sendInvoiceData(data: InvoiceData): Observable<InvoiceDataResponse> {
    return this.profileGateway.sendInvoiceData(data);
  }
}
