import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FileUploadController } from '@prlw/core/profile/file-upload.controller';
import { fileBrowser } from './file-upload.utils';

@Component({
  selector: 'prlw-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FileUploadComponent {
  public isHover: boolean = false;
  @ViewChild('inputFile', { static: true }) public inputFile!: ElementRef;

  constructor(private readonly filesController: FileUploadController) {}

  public hovered(state: boolean): void {
    this.isHover = state;
  }

  public onUpload(): void {
    this.inputFile.nativeElement.click();
  }

  public onFileDropped(event: DragEvent): void {
    const files = event as unknown as File[];
    this.sendFiles(files);
  }

  public onFileBrowser(event: Event): void {
    this.filesController.addFiles(fileBrowser(event));
  }

  private sendFiles(files: File[]): void {
    const result: File[] = Array.from(files).map((f) => f);
    this.filesController.addFiles(result);
  }
}
