import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[prlwClickOutside]',
  standalone: false,
})
export class ClickOutsideDirective {
  @Output() public readonly eventToggle = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(targetElement: HTMLElement | undefined): void {
    const clickedInside =
      this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.eventToggle.emit();
    }
  }

  @HostListener('window:keyup', ['$event'])
  private onClose(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.eventToggle.emit();
    }
  }

  constructor(private readonly _elementRef: ElementRef) {}
}
