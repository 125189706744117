import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HttpProfileGateway } from './profile.gateway';
import { PROFILE_GATEWAY } from '@prlw/core/profile/profile-gateway.token';
import { PROFILE_DATA_CONFIG } from './profile-data-config.token';

export type ProfileDataConfig = {
  apiPrefix: string;
};

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: PROFILE_GATEWAY, useClass: HttpProfileGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class ProfileDataModule {
  public static forRoot(
    config: ProfileDataConfig,
  ): ModuleWithProviders<ProfileDataModule> {
    return {
      ngModule: ProfileDataModule,
      providers: [{ provide: PROFILE_DATA_CONFIG, useValue: config }],
    };
  }
}
