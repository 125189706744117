import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CommonPaneComponent,
  TCommonPaneAppearance,
} from '@prlw/common/common-pane/common-pane.component';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';
import { map } from 'rxjs';

@Component({
  selector: 'prlw-title-label-ui',
  imports: [CommonModule, ResponsiveDirective, CommonPaneComponent],
  templateUrl: './title-label.component.html',
  styleUrl: './title-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLabelComponent {
  @Input() title: string | null = null;
  @Input() svgName: string | null = null;
  @Input() appearance: TCommonPaneAppearance = null;
  @Input() isPro: boolean | null = false;
  @Input() backgroundColor: 'white' | 'yellow' | null = null;

  constructor(private responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;

  isTransparent$ = this.responsiveService.xsmall$.pipe(
    map((xsmall) => (xsmall ? 'transparent' : null)),
  );
}
