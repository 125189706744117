import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploadController } from '@prlw/core/profile/file-upload.controller';
import { FileUploadSteps } from '@prlw/core/profile/file-upload.entity';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prlw-upload-steps',
  templateUrl: './upload-steps.component.html',
  styleUrls: ['./upload-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UploadStepsComponent {
  public files$: Observable<FileUploadSteps>;

  constructor(private readonly filesController: FileUploadController) {
    this.files$ = filesController.files$.pipe(
      map((files) => Boolean(files.length)),
      map((result) =>
        result ? FileUploadSteps.Uploaded : FileUploadSteps.Preload,
      ),
    );
  }
}
