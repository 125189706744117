import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  ConfirmStatus,
  ProfileController,
} from '@prlw/core/profile/profile.controller';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonalData, Profile } from '@prlw/core/profile/profile.entity';
import { TimerService } from '@prlw/infrastructure/timer/timer.service';
import { FeedbackOverlayService } from '../../feedback/feedback-overlay.service';
import { PrlwAuthError } from '@prlw/infrastructure/errors/error-types/error-types';
import { AuthErrorCode } from '@prlw/infrastructure/errors/error-codes/client-error-codes';

@Component({
  selector: 'prlw-personal-data',
  templateUrl: './personal-data.container.html',
  styleUrls: ['./personal-data.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PersonalDataContainer implements OnDestroy {
  public readonly profile$: Observable<Profile>;
  public personalData$: Observable<PersonalData>;
  public confirmEmailStatus$: Observable<ConfirmStatus>;
  public email: string = '';
  private readonly _error$ = new BehaviorSubject<PrlwAuthError | null>(null);
  private readonly _destroy$ = new Subject<void>();
  private readonly _attemptsByCode$ = new ReplaySubject<{
    code: string;
    email: string;
  }>(1);
  public timer$?: Observable<number | null>;

  constructor(
    private readonly timerService: TimerService,
    private readonly profileController: ProfileController,
    private readonly feedbackOverlayService: FeedbackOverlayService,
  ) {
    this.profile$ = profileController.profile$;
    this.personalData$ = profileController.getPersonalData();
    this.confirmEmailStatus$ = this.profileController.confirmEmailStatus$;
    this.timer$ = this.timerService.getTime();
    this._attemptsByCode$
      .pipe(
        tap(() => this._cleanError()),
        switchMap(({ code, email }) =>
          this.profileController.codeConfirm({ code, email }),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((result) => {
        if ('success' in result) {
          if (result.success) {
            this.profileController.setStatus(ConfirmStatus.CONFIRMED);
          }
        } else {
          this._emitError();
        }
      });
  }

  public showFeedbackModal(): void {
    this.feedbackOverlayService.show();
  }

  public takeCode(email: string): void {
    this.timerService.clearTimer();
    this._cleanError();
    this.profileController
      .takeCode(email)
      .pipe(first(), takeUntil(this._destroy$))
      .subscribe((data) => {
        if ('codesWasSent' in data) {
          this.timerService.setTime(data.timeToNextSend);
          if (data.codesWasSent) {
            this.email = email;
            this.profileController.setStatus(ConfirmStatus.CONFIRM_BY_CODE);
          }
        } else {
          this._emitError();
        }
      });
  }

  public codeConfirm(code: string): void {
    const data = { code, email: this.email };
    this._attemptsByCode$.next(data);
  }

  public get error$(): Observable<PrlwAuthError | null> {
    return this._error$;
  }

  private _cleanError(): void {
    this._error$.next(null);
  }

  private _emitError(): void {
    this._error$.next(new PrlwAuthError(AuthErrorCode.WrongConfirmationCode));
  }

  public editEmail(): void {
    this.timerService.clearTimer();
    this.profileController.setStatus(ConfirmStatus.INIT);
  }

  public onSubmit(personalData: PersonalData): void {
    this.profileController
      .setPersonalData(personalData)
      .pipe(first(), takeUntil(this._destroy$))
      .subscribe(
        () => (this.personalData$ = this.profileController.getPersonalData()),
      );
  }

  public ngOnDestroy(): void {
    this.timerService.clearTimer();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
