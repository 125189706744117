import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpFuturesGateway } from './futures.gateway';
import { FUTURES_GATEWAY } from '@prlw/core/futures/futures-gateway.token';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: FUTURES_GATEWAY, useClass: HttpFuturesGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class FuturesDataModule {}
