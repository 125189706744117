import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Profile } from '@prlw/core/profile/profile.entity';

@Component({
  selector: 'prlw-demo-access-ui',
  templateUrl: './demo-access.component.html',
  styleUrls: ['./demo-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DemoAccessComponent {
  @Input() public profile!: Profile;
  @Output() public eventGoToProfilePage = new EventEmitter<void>();
  @Output() public eventLogout = new EventEmitter<void>();

  public mapping: { [k: string]: string } = {
    one: '# день',
    few: '# дня',
    other: '# дней',
  };
}
