import { NgModule } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CUSTOMERS_GATEWAY } from '@prlw/core/customers/customers.gateway';
import { HttpCustomersGateway } from './customers.gateway';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: CUSTOMERS_GATEWAY, useClass: HttpCustomersGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CustomersDataModule {}
