import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import pluralize from '@prlw/libs/pluralize/pluralize';
import {
  MarketTime,
  MarketTimeTarget,
} from '@prlw/core/market-time/market-time.entity';

@Component({
  selector: 'prlw-market-time-component',
  templateUrl: './market-time.component.html',
  styleUrls: ['./market-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MarketTimeComponent {
  @Input() public marketTime!: MarketTime;
  @Input() public xsmall: boolean | null = null;

  public get day(): boolean {
    const secondsInDay = 86400;
    const day = 1;
    return this.marketTime.value / secondsInDay >= day;
  }

  public get tradingStatus(): boolean {
    return this.marketTime.target === MarketTimeTarget.Start;
  }

  public pluralize(value: number): string {
    const DAY = 24;
    const HOUR = 60;
    const time = Math.floor(value / (HOUR * HOUR * DAY));
    return pluralize(time, ['день', 'дня', 'дней']);
  }
}
