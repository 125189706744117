import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Organization } from '@prlw/core/profile/organization.entity';

@Component({
  selector: 'prlw-profile-organization-tab-ui',
  templateUrl: './profile-organization-tab.component.html',
  styleUrls: ['./profile-organization-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProfileOrganizationTabComponent {
  @Input() public organization: Organization | null | undefined;
  @Input() public areRegulationsSigned?: boolean;
}
