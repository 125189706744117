<div class="arrow-up" [class.alert]="profile.isAlertActive"></div>
<div class="demo-access">
  <div [class.alert]="profile.isAlertActive" class="user-info">
    <p class="user-info__name">{{ profile.fullName }}</p>
    <p class="user-info__organization-name">
      {{ profile.organization?.name }}
    </p>
  </div>
  <p
    *ngIf="profile?.isDemo"
    class="demo-access-period"
    [class.alert]="profile.isAlertActive"
    data-test="demo-status"
  >
    {{
      profile.expiresDay < 1
        ? 'Демо доступ закончен'
        : (profile.expiresDay | i18nPlural: mapping) + ' до конца демо-доступа'
    }}
  </p>
  <div>
    <a
      class="action"
      (click)="eventGoToProfilePage.emit()"
      data-test="navigate-to-profile"
    >
      <prlw-icon name="profile-invert" class="profile-icon"></prlw-icon>
      <span class="action__text"> Мой профиль </span>
    </a>
    <a
      class="action"
      (click)="eventLogout.emit()"
      data-test="navigate-to-sign-in"
    >
      <prlw-icon name="logout" class="profile-icon"></prlw-icon>
      <span class="action__text"> Выход </span>
    </a>
  </div>
</div>
