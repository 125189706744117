import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Layout } from '../layout/layout.enum';
import { IPage } from '../page/page.interface';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { catchError, combineLatest, map, of, switchMap, timer } from 'rxjs';
import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { UserDataService } from '@prlw/core/customers/oidc/user-data.service';
import { AuthLoginController } from '@prlw/core/auth/auth-login/auth-login.controller';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserActivityService } from '@prlw/libs/user-activity/user-activity.service';

@Component({
  selector: 'prlw-unauthorized',
  imports: [CommonModule],
  templateUrl: 'unauthorized.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedPage implements IPage {
  public readonly headerTitle = 'Авторизация';
  public readonly pageTitle = 'Авторизация';
  public readonly layout = Layout.Basic;

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly jwtStorage: JwtStorage,
    private readonly userDataService: UserDataService,
    private readonly authLoginController: AuthLoginController,
    private readonly router: Router,
    private readonly userActivityService: UserActivityService,
  ) {}

  success$ = this.oidcSecurityService.isAuthenticated$.pipe(
    switchMap(({ isAuthenticated }) => {
      if (isAuthenticated) {
        return combineLatest([
          this.oidcSecurityService.getIdToken(),
          this.oidcSecurityService.getUserData(),
        ]).pipe(
          switchMap(([idToken, userData]) => {
            this.jwtStorage.updateTokens(idToken, idToken);
            this.userDataService.setUserData(userData);
            this.userActivityService.init();
            return this.authLoginController.checkInLogin().pipe(
              switchMap(() => this.router.navigate(['/'])),
              catchError(() => of(false)),
            );
          }),
        );
      }
      this.oidcSecurityService.authorize();
      return timer(5000).pipe(map(() => false));
    }),
  );
}
