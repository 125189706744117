import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthInitializer {
  constructor(private readonly oidcSecurityService: OidcSecurityService) {}

  checkAuth$ = this.oidcSecurityService.checkAuth().pipe(
    switchMap((res) => {
      if (res.errorMessage)
        return this.oidcSecurityService.logoffAndRevokeTokens();
      return of(res);
    }),
  );
}
