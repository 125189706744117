import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CustomersGateway,
  TGetOrganizationToLinkUserResponse,
  TPostUserOrganizationLinkWithExistedOrganizationParams as TPostUserOrganizationLinkWithExistedOrganizationParams,
  TPostUserOrganizationLinkWithNewOrganizationParams as TPostUserOrganizationLinkWithNewOrganizationParams,
  TPostOtpEmailParams,
  TPostOtpEmailResponse,
  TPostUserUpdateNameEmailParams,
  TPostMarketerQuestionnaireParams,
} from '@prlw/core/customers/customers.gateway';
import { TSuccessResponse } from '@prlw/core/customers/types';
import { TUser } from '@prlw/core/customers/user/types';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpCustomersGateway implements CustomersGateway {
  constructor(private readonly httpClient: HttpClient) {}

  getOrganizationToLinkUser(params: { inn: string }) {
    return this.httpClient
      .get<
        TSuccessResponse<TGetOrganizationToLinkUserResponse>
      >(`${process.env['CUSTOMERS_API_URL']}/api/organization/link?inn=${params.inn}`)
      .pipe(catchError((err: HttpErrorResponse) => of(err)));
  }

  postUserOrganizationLinkWithExistedOrganization(
    params: TPostUserOrganizationLinkWithExistedOrganizationParams,
  ) {
    return this.httpClient.post<TSuccessResponse<string>>(
      `${process.env['CUSTOMERS_API_URL']}/api/user-organization/link-with-existed-organization`,
      params,
    );
  }

  postUserOrganizationLinkWithNewOrganization(
    params: TPostUserOrganizationLinkWithNewOrganizationParams,
  ) {
    return this.httpClient.post<TSuccessResponse<string>>(
      `${process.env['CUSTOMERS_API_URL']}/api/user-organization/link-with-new-organization`,
      params,
    );
  }

  postOtpEmail(params: TPostOtpEmailParams) {
    return this.httpClient.post<TSuccessResponse<TPostOtpEmailResponse>>(
      `${process.env['CUSTOMERS_API_URL']}/api/otp/email`,
      params,
    );
  }

  postUserUpdateNameEmail(params: TPostUserUpdateNameEmailParams) {
    return this.httpClient.post<TSuccessResponse<TUser>>(
      `${process.env['CUSTOMERS_API_URL']}/api/user/update-name-email`,
      params,
    );
  }

  postMarketerQuestionnaire(params: TPostMarketerQuestionnaireParams) {
    return this.httpClient.post<TSuccessResponse<string>>(
      `${process.env['CUSTOMERS_API_URL']}/api/marketer-questionnaire`,
      params,
    );
  }
}
