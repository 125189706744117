import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'prlw-preloader-circle-ui',
  imports: [LottieComponent],
  templateUrl: './preloader-circle.component.html',
  styleUrl: './preloader-circle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreloaderCircleComponent implements OnChanges {
  options = {
    path: '/assets/animations/main-loader.json',
  };

  @Input() active: boolean | null = null;

  constructor(private ngZone: NgZone) {}

  private animationItem?: AnimationItem;
  animationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
  }

  ngOnChanges(): void {
    if (this.active === true) {
      this.ngZone.runOutsideAngular(() => {
        this.animationItem?.play();
      });
    }
    if (this.active === false) {
      this.ngZone.runOutsideAngular(() => {
        this.animationItem?.stop();
      });
    }
  }
}
