import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TOrganization } from './organization/types';
import { TSuccessResponse } from './types';
import { HttpErrorResponse } from '@angular/common/http';
import { TUser } from './user/types';

export const CUSTOMERS_GATEWAY = new InjectionToken<CustomersGateway>(
  'customers-gateway',
);

export type TGetOrganizationToLinkUserResponse = Omit<
  TOrganization,
  'uuid' | 'isBlocked' | 'createDate' | 'updateDate'
> & {
  uuid?: string;
};

export type TGetOrganizationToLinkUserParams = {
  inn: string;
};

export type TPostUserOrganizationLinkWithExistedOrganizationParams = {
  uuid: string;
  jobTitle: string | null;
};

export type TPostUserOrganizationLinkWithNewOrganizationParams = {
  jobTitle: string | null;
  name: string;
  inn: string;
  kpp: string | null;
  okpo: string | null;
  factAddress: string | null;
  legalAddress: string | null;
  director: string | null;
  registrationDate: Date | null;
  website: string | null;
  phoneNumber: string | null;
  scope: string | null;
};

export type TPostUserUpdateNameEmailParams = {
  familyName: string;
  givenName: string;
  middleName: string;
  email: string;
  code: string;
};

export type TPostOtpEmailParams = {
  email: string;
  step: 'sign-in' | 'validation';
};

export type TPostOtpEmailResponse = {
  ttl: number;
};

export type TPostMarketerQuestionnaireParams = {
  favoriteProductGroups: {
    name: string;
    code: string;
  }[];
  favoriteDestinations: {
    id: string;
    name: string;
  }[];
  monthyVolume: number;
  annualTurnover: number;
};

export interface CustomersGateway {
  getOrganizationToLinkUser(
    params: TGetOrganizationToLinkUserParams,
  ): Observable<
    TSuccessResponse<TGetOrganizationToLinkUserResponse> | HttpErrorResponse
  >;

  postUserOrganizationLinkWithExistedOrganization(
    params: TPostUserOrganizationLinkWithExistedOrganizationParams,
  ): Observable<TSuccessResponse<string>>;

  postUserOrganizationLinkWithNewOrganization(
    params: TPostUserOrganizationLinkWithNewOrganizationParams,
  ): Observable<TSuccessResponse<string>>;

  postOtpEmail(
    params: TPostOtpEmailParams,
  ): Observable<TSuccessResponse<TPostOtpEmailResponse>>;

  postUserUpdateNameEmail(
    params: TPostUserUpdateNameEmailParams,
  ): Observable<TSuccessResponse<TUser>>;

  postMarketerQuestionnaire(
    params: TPostMarketerQuestionnaireParams,
  ): Observable<TSuccessResponse<string>>;
}
