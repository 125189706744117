import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextTagComponent } from '@prlw/common/text-tag/text-tag.component';

@Component({
  selector: 'prlw-text-tag-otp',
  imports: [CommonModule, TextTagComponent],
  templateUrl: './text-tag-otp.component.html',
  styleUrl: './text-tag-otp.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextTagOtpComponent {
  @Input() instrumentCode: string | null = null;
  @Input() size: 'small' | 'medium' = 'small';

  isOtp() {
    return this.instrumentCode?.[this.instrumentCode.length - 1] === 'J';
  }
}
