import { Injectable } from '@angular/core';
import { LogoutController } from '@prlw/core/auth/logout/logout.controller';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscriber, tap, timer, withLatestFrom } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TData = Record<string, any> & { dataType: string };

export type TOpen = {
  type: 'open';
};

export type TMessage<T extends TData> = {
  type: 'message';
  data: T[];
};

export const eventGuard =
  <T>(str: string) =>
  (item: unknown): item is T =>
    typeof item === 'object' &&
    item !== null &&
    'dataType' in item &&
    item.dataType === str;

export const isMessageEvent = <T extends TData>(
  event: TMessage<T> | TOpen,
): event is TMessage<T> => event.type === 'message';
export const isOpenEvent = <T extends TData>(
  event: TMessage<T> | TOpen,
): event is TOpen => event.type === 'open';

@Injectable({
  providedIn: 'root',
})
export class EventSourceService {
  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly logoutController: LogoutController,
  ) {}

  createEventSource<T extends TData>(path: string) {
    return new Observable((observer: Subscriber<TMessage<T> | TOpen>) => {
      let eventSource: EventSource | null = null;

      const findEventSourceSubscription = timer(0, 1000)
        .pipe(
          withLatestFrom(this.oidcSecurityService.getIdToken()),
          tap(([, idToken]) => {
            if (
              eventSource === null ||
              eventSource?.readyState === EventSource.CLOSED
            ) {
              eventSource = new EventSource(`${path}?token=${idToken}`);

              eventSource.onopen = () => {
                observer.next({
                  type: 'open',
                });
              };

              eventSource.onmessage = (ev) => {
                const data = JSON.parse(ev.data) as T[];
                if (data[0]?.dataType === 'TokenExpiredError') {
                  eventSource?.close();
                  this.logoutController.logout();
                  return;
                }

                observer.next({
                  type: 'message',
                  data,
                });
              };

              eventSource.onerror = () => {};
            }
          }),
        )
        .subscribe();

      return {
        unsubscribe() {
          eventSource?.close();
          findEventSourceSubscription.unsubscribe();
          observer.complete();
        },
      };
    });
  }
}
