import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DESTINATIONS_GATEWAY } from '@prlw/core/destinations/destinations.gateway';
import { HttpDestinationsGateway } from '@prlw/data/destinations/destinations.gateway';
import {
  DESTINATIONS_DATA_CONFIG,
  DestinationsDataConfig,
} from '@prlw/data/destinations/destinations-data.config';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: DESTINATIONS_GATEWAY, useClass: HttpDestinationsGateway },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class DestinationsDataModule {
  public static forRoot(
    config: DestinationsDataConfig,
  ): ModuleWithProviders<DestinationsDataModule> {
    return {
      ngModule: DestinationsDataModule,
      providers: [{ provide: DESTINATIONS_DATA_CONFIG, useValue: config }],
    };
  }
}
