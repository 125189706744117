import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonPaneComponent } from '../common-pane/common-pane.component';
import { IconsModule } from '@prlw/libs/icons/icons.module';

@Component({
  selector: 'prlw-mobile-menu-sandwich-button-ui',
  imports: [CommonPaneComponent, IconsModule],
  templateUrl: './mobile-menu-sandwich-button.component.html',
  styleUrl: './mobile-menu-sandwich-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuSandwichButtonComponent {
  @Output() eventClick = new EventEmitter<void>();
}
