import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  HostBinding,
  ElementRef,
} from '@angular/core';
import { TabComponent } from '@prlw/libs/tabs/tab.component';
import { TabsService } from '@prlw/libs/tabs/tabs.service';

@Component({
  selector: 'prlw-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TabsService],
  standalone: false,
})
export class TabsComponent implements AfterViewInit {
  @Input() @HostBinding('style.--i-tabs-active-bg') public activeTabBg: string =
    '#f5f5f5';
  @Input() public initTabId?: string | null;
  @ContentChildren(TabComponent) public tabs?: QueryList<TabComponent>;
  public activeTab: TabComponent | null = null;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly tabsService: TabsService,
    private readonly elementRef: ElementRef,
  ) {}

  public activeTabId$ = this.tabsService.activeTabId$;

  public ngAfterViewInit(): void {
    if (!this.tabs?.length) {
      return;
    }
    if (this.tabs.find((tab) => !tab.id)) {
      throw new Error('each tab should have an id');
    }

    this.tabsService.activeTabId$.subscribe((id) => {
      this.activeTab = this.tabs?.find((tab) => tab.id === id) ?? null;
      this.cdr.detectChanges();
      this.scrollActiveTabIntoView();
    });
    if (this.initTabId) {
      if (this.tabs.map((tab) => tab.id).includes(this.initTabId)) {
        this.setActiveTab(this.initTabId);
        return;
      }
    }
    this.setActiveTab(this.tabs.first.id);
  }

  public setActiveTab(id: string): void {
    this.tabsService.setActiveTabId(id);
  }

  public scrollActiveTabIntoView() {
    (this.elementRef.nativeElement as HTMLElement)
      ?.querySelector('div.active')
      ?.scrollIntoView({ block: 'nearest' });
  }
}
